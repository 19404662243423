<script lang="ts" setup>
import { ref, computed } from "vue";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import RegisterUsageYearly from "@/views/project/location/wizard/RegisterUsageYearly.vue";

const emits = defineEmits(["return-register-data"]);

setInteractionMode("eager");

const props = defineProps({
  registerTypes: [],
  registerEnergyTypes: [],
  registerUnitTypes: [],
});

const registers = ref([]);
const currentEdit = ref(undefined);
const lastId = ref(0);

const headers = computed(() => {
  return [
    { text: "", value: "actions", sortable: false, width: 10 },
    { text: "Type", value: "registerType", width: 325 },
    { text: "Energietype", value: "registerEnergyType", width: 225 },
    { text: "Eenheid", value: "registerUnitType", width: 225 },
    { text: "Ean", value: "ean", width: 200 },
    { text: "", value: "confirm", sortable: false, width: 10 },
    { text: "", value: "cancel", sortable: false, width: 10 },
    { text: "", value: "delete", sortable: false, width: 10 },
    { text: "", sortable: false, width: 20 },
    { text: "Verbruiken", value: "id" },
  ];
});

function addRegister() {
  currentEdit.value = { index: 0, id: "new" };
  registers.value.splice(0, 0, currentEdit.value);
}

const obsRegister = ref(null);

async function editRow(id, setEdit, doSave, doDelete) {
  const itemIndex = registers.value.findIndex((x) => x.id == id);
  if (setEdit) {
    if (itemIndex >= 0) {
      currentEdit.value = {
        index: itemIndex,
        id: registers.value[itemIndex].id,
        registerType: registers.value[itemIndex].registerType,
        registerEnergyType: registers.value[itemIndex].registerEnergyType,
        registerUnitType: registers.value[itemIndex].registerUnitType,
        ean: registers.value[itemIndex].ean,
      };
    }
  } else {
    if (doDelete) {
      registers.value.splice(currentEdit.value.index, 1);
    } else {
      const valid = await obsRegister.value.validate();
      if (valid) {
        if (doSave) {
          if (id === "new") {
            currentEdit.value.id = lastId.value + 1;
            lastId.value = lastId.value + 1;
          }
        } else {
          if (currentEdit.value.id === "new") {
            registers.value.splice(0, 1);
          } else {
            registers.value[itemIndex].registerType = currentEdit.value.registerType;
            registers.value[itemIndex].registerEnergyType = currentEdit.value.registerEnergyType;
            registers.value[itemIndex].registerUnitType = currentEdit.value.registerUnitType;
            registers.value[itemIndex].ean = currentEdit.value.ean;
          }
        }
        currentEdit.value = undefined;
      }
    }
    emits("return-register-data", registers.value);
  }
}

function inEdit(id) {
  return id == "any" ? currentEdit.value && currentEdit.value.id.length > 0 : currentEdit.value && currentEdit.value.id == id;
}

function updateRegisterUsages(data) {
  const registerId = data[0].registerId;
  const registerIndex = registers.value.findIndex((x) => x.id == registerId);
  registers.value[registerIndex].usages = data;
  console.log("updateRegisterUsages, registerIndex, registers: ", registerIndex, registers.value);
  emits("return-register-data", registers.value);
}
</script>

<template>
  <v-container fluid pa-0>
    <v-row class="overview-wrapper">
      <v-col cols="1">
        <header class="app-welcome-message">
          <h4>Meters</h4>
        </header>
      </v-col>
      <v-col cols="1">
        <v-btn small dark class="primary" @click="addRegister">
          <v-icon small dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <ValidationObserver ref="obsRegister">
      <v-form>
        <v-data-table dense :headers="headers" hide-default-footer :items="registers" class="no-stripes">
          <template v-slot:[`item.registerType`]="{ item }">
            <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
              <v-select
                hide-details="auto"
                :items="props.registerTypes"
                item-text="description"
                item-value="key"
                persistent-placeholder
                dense
                v-model="item.registerTypeId"
                :error-messages="errors"
                :outlined="inEdit(item.id)"
                :class="{ noBorders: !inEdit(item.id) }"
                :readonly="!inEdit(item.id)"
              ></v-select>
            </ValidationProvider>
          </template>

          <template v-slot:[`item.registerEnergyType`]="{ item }">
            <ValidationProvider name="RegisterEnergyType" rules="required" v-slot="{ errors }">
              <v-select
                hide-details="auto"
                :items="props.registerEnergyTypes"
                item-text="description"
                item-value="key"
                persistent-placeholder
                dense
                v-model="item.registerEnergyTypeId"
                :error-messages="errors"
                :outlined="inEdit(item.id)"
                :class="{ noBorders: !inEdit(item.id) }"
                :readonly="!inEdit(item.id)"
              ></v-select>
            </ValidationProvider>
          </template>
          <template v-slot:[`item.registerUnitType`]="{ item }">
            <ValidationProvider name="UnitType" rules="required" v-slot="{ errors }">
              <v-select
                hide-details="auto"
                :items="props.registerUnitTypes"
                item-text="description"
                item-value="key"
                persistent-placeholder
                dense
                v-model="item.registerUnitTypeId"
                :error-messages="errors"
                :outlined="inEdit(item.id)"
                :class="{ noBorders: !inEdit(item.id) }"
                :readonly="!inEdit(item.id)"
              ></v-select>
            </ValidationProvider>
          </template>
          <template v-slot:[`item.ean`]="{ item }">
            <span v-if="!inEdit(item.id)">
              {{ item.ean }}
            </span>
            <ValidationProvider v-else name="Ean" rules="min:18|max:18" v-slot="{ errors }">
              <v-text-field
                hide-details="auto"
                :outlined="inEdit(item.id)"
                :class="{ noBorders: !inEdit(item.id) }"
                persistent-placeholder
                dense
                v-model="item.ean"
                :error-messages="errors"
                :readonly="!inEdit(item.id)"
              ></v-text-field>
            </ValidationProvider>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="inEdit('any')" @click="editRow(item.id, true, false, false)" icon small plain v-bind="attrs" v-on="on">
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Bewerken</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.confirm`]="{ item }">
            <v-tooltip v-if="inEdit(item.id)" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!inEdit(item.id)" @click="editRow(item.id, false, true, false)" icon small plain v-bind="attrs" v-on="on">
                  <v-icon small> mdi-checkbox-marked-circle </v-icon>
                </v-btn>
              </template>
              <span>Opslaan</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.cancel`]="{ item }">
            <v-tooltip v-if="inEdit(item.id)" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!inEdit(item.id)" @click="editRow(item.id, false, false, false)" icon small plain v-bind="attrs" v-on="on">
                  <v-icon small> mdi-cancel </v-icon>
                </v-btn>
              </template>
              <span>Annuleren</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-tooltip v-if="inEdit(item.id)" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!inEdit(item.id)" @click="editRow(item.id, false, false, true)" icon small plain v-bind="attrs" v-on="on">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Verwijderen</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.id`]="{ item }">
            <div style="white-space: pre-wrap">
              <RegisterUsageYearly :registerId="item.id.toString()" @return-register-usage-data="updateRegisterUsages"> </RegisterUsageYearly>
            </div>
          </template>
        </v-data-table>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>
