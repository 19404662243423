<script lang="ts" setup>
import { ref, computed } from "vue";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";

const emits = defineEmits(["return-register-usage-data"]);

setInteractionMode("eager");

const props = defineProps({
  registerId: {
    type: String,
  },
});

const usages = ref([]);
const currentEdit = ref(undefined);
const lastId = ref(0);

const headers = computed(() => {
  return [
    { text: "", value: "actions", sortable: false, width: 10 },
    { text: "Jaar", value: "year" },
    { text: "Waarde", value: "value" },
    { text: "", value: "confirm", sortable: false, width: 10 },
    { text: "", value: "cancel", sortable: false, width: 10 },
    { text: "", value: "delete", sortable: false, width: 10 },
  ];
});

function addUsage() {
  currentEdit.value = { index: 0, id: "new", registerId: props.registerId };
  usages.value.splice(0, 0, currentEdit.value);
}

const obsUsage = ref(null);

async function editRow(id) {
  const itemIndex = usages.value.findIndex((x) => x.id == id);
  if (itemIndex >= 0) {
    currentEdit.value = { index: itemIndex, id: usages.value[itemIndex].id, registerId: props.registerId, year: usages.value[itemIndex].year, value: usages.value[itemIndex].value };
  }
}

async function cancelRow(id) {
  const itemIndex = usages.value.findIndex((x) => x.id == id);
  const valid = await obsUsage.value.validate();
  if (valid) {
    if (currentEdit.value.id === "new") {
      usages.value.splice(0, 1);
    } else {
      usages.value[itemIndex].registerId = props.registerId;
      usages.value[itemIndex].year = currentEdit.value.year;
      usages.value[itemIndex].value = currentEdit.value.value;
    }
    currentEdit.value = undefined;
  }
  emits("return-register-usage-data", usages.value);
}

async function saveRow(id) {
  console.log("RegisterUsageYearly, saveRow, valid: ");
  let valid = false;
  valid = await obsUsage.value.validate();
  console.log("RegisterUsageYearly, saveRow, valid: ", valid);
  if (valid) {
    if (id === "new") {
      currentEdit.value.id = lastId.value + 1;
      lastId.value = lastId.value + 1;
    }
    currentEdit.value = undefined;
  }
  emits("return-register-usage-data", usages.value);
}

async function deleteRow() {
  usages.value.splice(currentEdit.value.index, 1);
  emits("return-register-usage-data", usages.value);
}

function inEdit(id) {
  return id == "any" ? currentEdit.value && currentEdit.value.id.length > 0 : currentEdit.value && currentEdit.value.id == id;
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <header class="app-welcome-message">
          <h4>Verbruiken</h4>
        </header>
      </v-col>
      <v-col cols="1">
        <v-btn small dark class="primary" @click="addUsage" :disabled="props.registerId == 'new'">
          <v-icon small dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <ValidationObserver ref="obsUsage">
      <v-form>
        <v-data-table dense :headers="headers" hide-default-footer :items="usages" class="noStripes">
          <template v-slot:[`item.year`]="{ item }">
            <span v-if="!inEdit(item.id)">
              {{ item.year }}
            </span>
            <ValidationProvider v-else name="Jaar" rules="required:numeric|between:1950,2050" v-slot="{ errors }">
              <v-text-field hide-details="auto" outlined persistent-placeholder dense v-model="item.year" :error-messages="errors" :readonly="false"></v-text-field>
            </ValidationProvider>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <span v-if="!inEdit(item.id)">
              {{ item.value }}
            </span>
            <ValidationProvider v-else name="Waarde" rules="required:decimal:4|between:0,999999999.9999" v-slot="{ errors }">
              <v-text-field hide-details="auto" outlined persistent-placeholder dense v-model="item.value" :error-messages="errors" :readonly="false"></v-text-field>
            </ValidationProvider>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="inEdit('any')" @click="editRow(item.id)" icon small plain v-bind="attrs" v-on="on">
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Bewerken</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.confirm`]="{ item }">
            <v-tooltip v-if="inEdit(item.id)" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!inEdit(item.id)" @click="saveRow(item.id)" icon small plain v-bind="attrs" v-on="on">
                  <v-icon small> mdi-checkbox-marked-circle </v-icon>
                </v-btn>
              </template>
              <span>Opslaan</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.cancel`]="{ item }">
            <v-tooltip v-if="inEdit(item.id)" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!inEdit(item.id)" @click="cancelRow(item.id)" icon small plain v-bind="attrs" v-on="on">
                  <v-icon small> mdi-cancel </v-icon>
                </v-btn>
              </template>
              <span>Annuleren</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-tooltip v-if="inEdit(item.id)" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!inEdit(item.id)" @click="deleteRow()" icon small plain v-bind="attrs" v-on="on">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Verwijderen</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>
